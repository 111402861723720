<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header">
                <div class="content__header-left">
                    <router-link v-if="isEdit" style="margin-right:20px" to="/reports/edx-analysis" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require('@/assets/img/Arrow-prev.svg')" />
                    </router-link>
                    <h1 class="heading-page-h1">{{ $translate('edx-analysis') }} {{isEdit ? $translate('edit') : $translate('create') }}</h1>
                </div>
                <div class="content__header-right">
					<WhitePlusButton @click="addRow">
                        {{ $translate('add--entry') }}
                    </WhitePlusButton>
                </div>
            </div>
            <div class="table-wrapper">
                <table class="table editable_table" :key="tableKey">
                    <thead>
                        <tr>
                            <th v-for="(columnName, index) in tableColumns" :key="index">
                                <div class="table-filter">
                                    <span>{{ columnName }}</span>
                                </div>
                            </th>
                            <th class="text-end">
                                <div class="table-filter">
                                    <span>{{ $translate('actions') }}</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" v-for="(row, rowIndex) in tableData" :key="rowIndex">
                            <td v-for="(columnData, columnIndex) in tableData[rowIndex]" :key="columnIndex" contenteditable="true" @blur="setCellContent($event, rowIndex, columnIndex)">
                                {{ columnData }}
                            </td>
                            <td class="text-end">
                                <div class="table-actions flex items-center justify-end">
                                    <button type="button" class="btn btn-icon red-color" @click="deleteRow(rowIndex)">
                                        <inline-svg :src="require(`@/assets/img/trash-delete.svg`)" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                      
                    </tbody>
                </table>
            </div>
            <div class="items-group cards border-top">
                <div class="cards__col-6">
                    <div class="el-form mt-15 flex-auto">
                        <label class="el-form__title">{{ $translate('report-name') }}</label> 
                        <input type="text" class="input" v-model="config.name"/>
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="el-form mt-15 flex-auto">
                        <label class="el-form__title">{{ $translate('product') }}</label> 
                        <DropdownAdvanced 
                            :options="products"
                            v-model="config.product"
                        ></DropdownAdvanced>
                    </div>
                </div>
            </div>
            <div class="items-group cards" style="padding-bottom: 68px;margin-top:25px">
                <div class="cards__col-6">
                    <div class="section-header">
                        <h1 style="margin-bottom:15px">{{ $translate('image') }}</h1>
                        <!-- <WhitePlusButton @click="addImages">Add Images</WhitePlusButton> -->
                    </div>
                  
                    <div class="item-body">
                        <div class="gallery">
                            <div class="gallery__item" v-for="image in images" :key="image.url">
                                <img :src="image.url" alt="" />
                                <button class="delete-gallery__item btn" data-target="delete-media" @click.prevent="deleteImage(image.url)">
                                    <inline-svg :src="require(`@/assets/img/x.svg`)"/>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div 
                        class="fake-dropzone" 
                        :class="{ 'drop-ready': dragState }" 
                        @drop.prevent.stop="dropImage"  
                        @dragenter.prevent @dragover.prevent
                        style="margin-top:58px"
                    >
                        <label>
                            <img src="@/assets/img/upload-image-icon.svg">
                            <span>{{ $translate('drag-&-drop-or-browse-to-choose-a-file') }}</span>
                            <input type="file" @change="addImage" multiple>
                        </label>
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="section-header">
                        <h1>{{ $translate('comments') }}</h1>
                        <WhitePlusButton @click="addComment">{{ $translate('add-comment') }}</WhitePlusButton>
                    </div>
                    <div class="el-form mt-15" v-for="id in Object.keys(comments)" :key="id">
                        <div class="el-form__title flex flex-end" style="text-align: right">
                            <button type="button" class="btn btn-icon red-color" @click="deleteComment(id)">
                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                            </button>
                        </div>
                        <textarea placeholder="Comment decsription" class="textarea" v-model="comments[id]"></textarea>
                    </div>
                </div>
            </div>
        </main>
        <div class="pagination wizard-actions fixed">
            <div class="pagination__body" v-if="isEdit">
                <button  @click="restoreFromBackup" type="button" class="btn btn-w-shadow btn-standart-2">
                    {{ $translate('cancel') }}
                </button>
            
                <button type="button" class="btn btn-primary btn-standart icon-rigth" @click="updateEDXReport" :class="{processing}">
                    {{ $translate('save') }}
                </button>
            </div>
            <div class="pagination__body" v-else>
                <router-link to="/reports/edx-analysis" type="button" class="btn btn-w-shadow btn-standart-2">
                    {{ $translate('cancel') }}
                </router-link>
            
                <button type="button" class="btn btn-primary btn-standart icon-rigth" @click="createEDXReport" :class="{processing}">
                    {{ $translate('create') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { getCustomReport } from "@/graphql/queries";
import { API,Storage } from "aws-amplify";
import { createCustomReport,updateCustomReport } from "@/graphql/mutations";

import Popups from "@/components/popups";
import Base from "@/components/base";
import Buttons from "@/components/buttons";
import SimplePagination from "./SimplePagination";

export default {
    components: {
        ...Popups,
        ...Base,
        ...Buttons,
        SimplePagination,
    },
    props:['id'],
    data() {
        return {
            tableColumns: ['Name', 'VET1 Map Sum Spectrum', 'Line Type', 'Apparent Concentration', 'k Ratio', 'Wt%', 'Wt% Sigma', 'Standard Label'],
            tableData: [],
            config:{
                name:null,
                product:null
            },
            comments: {},
            images:[],
            dragState:false,
            tableKey:0,
            processing: false,
            backup:null,
        };
    },
    computed: {
        products(){
            let products = this.$store.state.products || []
            return products.map(p=>({id:p.id,name:p.title}))
        },
        isEdit(){
            return !!this.id
        }
        
    },
    
    created(){
        if(this.isEdit){
            console.log("edit mode")
            this.getReport()
        }else{
            this.addRow()
            console.log("add mode");
        }
    },
    methods: {


        async getReport() {
            const report = await API.graphql({
                query: getCustomReport,
                variables: {
                    id: this.id,
                },
            }).then((res) => res.data.getCustomReport);
        
            await this.setReportInfo(report)
            this.backup = JSON.parse(JSON.stringify(report));
        },
        
        async setReportInfo(newReport){
            this.config.name=newReport.name
            this.config.product=newReport.product
            newReport.comments.map((comment,index)=>{
                this.comments[index]=comment
            })
            let requestsImages=newReport.images.map(async r=>{
                return {
                    url: await Storage.get(r),
                    isLoad:true,
                    key:r
                }
            })
            await Promise.all(requestsImages).then(res=>this.images=res)
            this.tableData=newReport.tableData
        },

        restoreFromBackup() {
            this.setReportInfo(this.backup)
        },


        async updateEDXReport() {
            this.processing = true;

            let images = [];
            let imagesRequests = this.images.map(async (img) => {
                if(img.isLoad){
                    return img.key
                }else{
                    let name = `/images/${img.url.split("/")[img.url.split("/").length - 1]}`;
                    const s3File = await Storage.put(name, img.file);
                    return s3File.key;
                }
            });
            await Promise.all(imagesRequests).then((res) => (images = res));
            const input = {
                ...this.config,
                tableData: this.tableData,
                images: images,
                comments: Object.values(this.comments),
            };
            const report = await API.graphql({
                query: updateCustomReport,
                variables: { 
                    input:{
                        id:this.id,
                        ...input
                    }
                },
            }).then((res) => res.data.updateCustomReport);

            console.log("creaed report", report);
            this.getReport()
            this.tableKey++

            this.processing = false;
        },




        async createEDXReport() {
            this.processing = true;

            let images = [];
            let imagesRequests = this.images.map(async (img) => {
                let name = `/images/${img.url.split("/")[img.url.split("/").length - 1]}`;
                const s3File = await Storage.put(name, img.file);
                return s3File.key;
            });
            await Promise.all(imagesRequests).then((res) => (images = res));
            const input = {
                ...this.config,
                tableColumns: this.tableColumns,
                tableData: this.tableData,
                images: images,
                comments: Object.values(this.comments),
                type:"edx"
            };
            const report = await API.graphql({
                query: createCustomReport,
                variables: { input },
            }).then((res) => res.data.createCustomReport);

            console.log("creaed report", report);
            this.tableKey++
            this.processing = false;
            this.$router.push("/reports/edx-analysis")
        },






        setCellContent(event, rowIndex, columnIndex) {
            this.tableData[rowIndex][columnIndex] = event.target.innerText;
            this.tableKey++;
        },
        deleteRow(rowIndex) {
            this.tableData.splice(rowIndex, 1);
            this.tableKey++;
        },

        addRow() {
            let newRow = Array(this.tableColumns.length).fill("");
            this.tableData.push(newRow);
            this.tableKey++;
        },

        

        addComment() {
            // this.comments[Date.now()]=""
            this.$set(this.comments, Date.now() + " ", "");
        },
        deleteComment(id) {
            this.$delete(this.comments, id);
        },
        dropImage(ev){
            const event = { target: ev.dataTransfer }
            this.addImage(event)
            this.dragState = false
        },
        
		dragOver(ev) {
			this.dragState = true
		},
        addImage(ev) {
            [...ev.target.files].forEach((file) => {
                this.images.push({
                    url: URL.createObjectURL(file),
                    file: file,
                });
            });
        },
        async deleteImage(url){
            const options={title:`Delete media?`,size:'sm',okLable:"Confirm",cancelLabel:"Cancel"} 
            await this.$dialogs.confirm('', options).then(async res=>{
                if(res.ok){
                    let index=this.images.findIndex(i=>i.url==url)
                    if(index!==-1){
                        this.images.splice(index,1)
                    }
                }
            })
        },
     
    },
};
</script>

<style scoped>
.cards__col-6 {
    padding-left: 25px;
}
.comment {
    padding-top: 25px;
}
.flex-end {
    justify-content: end;
}
.section-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .max-witdth{

} */
</style>
